import classNames from 'classnames/bind';
import { BUTTON_TYPES } from 'components/Button/Button';
import { GENERAL_ICONS } from 'components/GeneralIcon/GeneralIcon';
import IconButton, { ALIGN_TYPES } from 'components/IconButton/IconButton';
import Money from 'components/Money/Money';
import PropTypes from 'prop-types';
import styles from './AddOrSubtract.module.css';

const cx = classNames.bind(styles);

const AddOrSubtract = ({ changeAmount, min, max, input, size, onChangeCustom, valueClassName }) => {
  const addToMax = () => {
    if (changeAmount + input.value > max) {
      return max;
    }

    return Math.floor((input.value + changeAmount) / changeAmount) * changeAmount;
  };

  const subtractToMin = () => {
    if (input.value - changeAmount <= min) {
      return min;
    }

    return Math.ceil((input.value - changeAmount) / changeAmount) * changeAmount;
  };

  return (
    <div className={cx('flex')}>
      <IconButton
        onClick={() => {
          const value = subtractToMin();
          input.onChange(value);
          onChangeCustom(value);
        }}
        align={ALIGN_TYPES.NONE}
        icon={GENERAL_ICONS.MINUS_CIRCLE}
        disabled={input.value <= min}
        buttonType={BUTTON_TYPES.NOSTYLE}
      />

      <div
        className={cx(
          'min-money-width',
          'text-center mx-sm',
          { small: size === 'small', large: size === 'large' },
          valueClassName
        )}
      >
        <Money cash={input.value} />
      </div>
      <IconButton
        onClick={() => {
          const value = addToMax();
          input.onChange(value);
          onChangeCustom(value);
        }}
        align={ALIGN_TYPES.NONE}
        icon={GENERAL_ICONS.PLUS_CIRCLE}
        disabled={input.value >= max}
        buttonType={BUTTON_TYPES.NOSTYLE}
      />
    </div>
  );
};

AddOrSubtract.propTypes = {
  changeAmount: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  input: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['small', 'large']),
  onChangeCustom: PropTypes.func,
};

AddOrSubtract.defaultProps = {
  onChangeCustom: () => {},
};

export default AddOrSubtract;
