import { STEP_GROUPS, STEP_GATE_NAMES, STEP_NAMES } from 'accounts/constants/steps';
import type { AgreementDetailModel, FlowStepModel } from 'api/models';
import { SetupDataTypes, STATUS, StepStatus } from 'models/enums';
import { HomeownerSetupStepNames } from 'models/enums/index';
import HomeownerAccountProperty from 'models/homeownerAccounts/HomeownerAccountProperty';
import HomeownerAccountUnit from 'models/homeownerAccounts/HomeownerAccountUnit';
import { BASE_PATHS } from 'routes/paths';

export function getStepGroupByName(name) {
  return STEP_GROUPS.find((group) => group.name === name);
}

export const STEPS_CONFIGURATION = [
  // InspectionSchedule
  {
    name: STEP_NAMES.UnitOccupancy,
    displayName: 'Occupancy',
    key: 'occupancy',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.InspectionSchedule,
  },
  {
    name: STEP_NAMES.PropertyFeatures,
    displayName: 'Property Features',
    key: 'features',
    group: getStepGroupByName(SetupDataTypes.Property),
    gate: STEP_GATE_NAMES.InspectionSchedule,
  },
  {
    name: STEP_NAMES.UnitFeatures,
    displayName: 'Home Features',
    key: 'features',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.InspectionSchedule,
  },
  {
    name: STEP_NAMES.PropertyHoa,
    displayName: 'HOA',
    key: 'hoa',
    group: getStepGroupByName(SetupDataTypes.Property),
    gate: STEP_GATE_NAMES.InspectionSchedule,
  },
  {
    name: STEP_NAMES.UnitInspectionPreferences,
    displayName: 'Schedule',
    key: 'inspection',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.InspectionSchedule,
  },
  {
    name: STEP_NAMES.UnitKeysEnhanced,
    displayName: 'Keys',
    key: 'keys-enhanced',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.InspectionSchedule,
  },
  {
    name: STEP_NAMES.UnitInspectionPrep,
    displayName: 'Inspection Prep',
    key: 'inspection-prep',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.InspectionSchedule,
  },
  {
    name: STEP_NAMES.UnitInspectionSchedule,
    displayName: 'Schedule',
    key: 'inspection-schedule',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.InspectionSchedule,
  },

  // Build Listing
  {
    name: STEP_NAMES.UnitListing,
    displayName: 'Details',
    key: 'listing',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.BuildListing,
  },
  {
    name: STEP_NAMES.UnitPreferencesPets,
    displayName: 'Pets',
    key: 'pet',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.BuildListing,
  },
  {
    name: STEP_NAMES.UnitPreferencesUtilities,
    displayName: 'Utilities',
    key: 'utilities',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.BuildListing,
  },
  {
    name: STEP_NAMES.UnitDisclosures,
    displayName: 'Disclosures',
    key: 'disclosures',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.BuildListing,
  },

  // NextLevelProfile
  {
    name: STEP_NAMES.UserProfile,
    displayName: 'About You',
    key: 'profile',
    group: getStepGroupByName(SetupDataTypes.User),
    gate: STEP_GATE_NAMES.NextLevelProfile,
  },
  {
    name: STEP_NAMES.UnitResidentExperience,
    displayName: 'Resident Experience',
    key: 'resident-experience',
    group: getStepGroupByName(SetupDataTypes.Home),
    gate: STEP_GATE_NAMES.NextLevelProfile,
  },

  // GetPaid
  {
    name: STEP_NAMES.UserIdentity,
    displayName: 'ID Verification',
    key: 'identity',
    group: getStepGroupByName(SetupDataTypes.User),
    gate: STEP_GATE_NAMES.GetPaid,
  },
  {
    name: STEP_NAMES.UserTaxes,
    displayName: 'Tax',
    key: 'taxes',
    group: getStepGroupByName(SetupDataTypes.UserPayment),
    gate: STEP_GATE_NAMES.GetPaid,
  },
  {
    name: STEP_NAMES.UserSsn,
    displayName: 'SSN',
    key: 'user-taxes',
    group: getStepGroupByName(SetupDataTypes.UserPayment),
    gate: STEP_GATE_NAMES.GetPaid,
  },
  {
    name: STEP_NAMES.PropertyInsurance,
    displayName: 'Insurance',
    key: 'insurance',
    group: getStepGroupByName(SetupDataTypes.Property),
    gate: STEP_GATE_NAMES.GetPaid,
  },
  {
    name: STEP_NAMES.UserPaymentMethods,
    displayName: 'Get Paid',
    key: 'payment-method',
    group: getStepGroupByName(SetupDataTypes.UserPayment),
    gate: STEP_GATE_NAMES.GetPaid,
  },
];

export function getStepByName(name) {
  return STEPS_CONFIGURATION.find((step) => step.name === name);
}

export function getSetupFlowStepsFromProperty({ setupFlow, user, property }) {
  if (!setupFlow) {
    return [];
  }

  return setupFlow.steps
    ?.filter((step) => Object.values(STEP_NAMES).includes(step.stepName) && !step.isHidden)
    ?.filter((step) => {
      if (step.dataType === SetupDataTypes.Property) {
        return property?.basicInfo.propertyId === step.dataUniqueId;
      }

      if (step.dataType === SetupDataTypes.Home) {
        return property?.units?.some((unit) => unit?.basicInfo.unitId === step.dataUniqueId);
      }

      if (step.dataType === SetupDataTypes.User) {
        return user?.userId === step.dataUniqueId;
      }

      return false;
    });
}

function getStepIndexByName(name) {
  return STEPS_CONFIGURATION.findIndex((step) => step.name === name);
}

export function sortStepsByGate(steps) {
  return steps?.sort((a, b) => {
    const firstItem = getStepIndexByName(a.stepName);
    const secondItem = getStepIndexByName(b.stepName);

    return a.unit?.basicInfo.unitNumber - b.unit?.basicInfo.unitNumber || firstItem - secondItem;
  });
}

export function getDisplayStatus(status) {
  if (status === StepStatus.Created) {
    return STATUS.NOT_TOUCHED;
  }

  if (status === StepStatus.Started || status === StepStatus.Incomplete) {
    return STATUS.INCOMPLETE;
  }

  if (status === StepStatus.Completed) {
    return STATUS.COMPLETED;
  }

  return status;
}

export function getStepPath(step, propertyId, unitId) {
  if (step.stepName === HomeownerSetupStepNames.UnitResidentExperience) {
    return `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}/setup-flow/${step.dataRelId}/steps/${step.group.key}/${step.key}/${step.uniqueId}`;
  }

  return `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}/setup-flow/${unitId}/steps/${step.group.key}/${step.key}/${step.uniqueId}`;
}

export function getStepPathByName(stepName, propertyId, unitId) {
  const stepConfig = getStepByName(stepName);

  return `${BASE_PATHS.HOMEOWNER_SETUP_FLOW}/${propertyId}/setup-flow/${unitId}/steps/${stepConfig?.group.key}/${stepConfig?.key}`;
}

export function isStepCompleted(step) {
  return step.status === StepStatus.Completed;
}

export function isStepHidden(step) {
  return step.isHidden;
}

export function isStepUnHidden(step) {
  return !step.isHidden;
}

export function isCurrentPropertyOrUnitStep(step: FlowStepModel, property?: HomeownerAccountProperty) {
  return (
    step.dataUniqueId === property?.basicInfo.propertyId ||
    property?.units.some((unit: HomeownerAccountUnit) => unit.basicInfo.unitId === step.dataUniqueId)
  );
}

export function getFilteredGateSteps({ allStepsByGate = {}, unitId, selectedProperty, user }) {
  return Object.keys(allStepsByGate).reduce((acc, curr) => {
    const gateStepsFiltered = allStepsByGate[curr].filter(
      (step) =>
        step.dataRelId === selectedProperty?.basicInfo.propertyId ||
        (unitId && step.stepName !== HomeownerSetupStepNames.UnitResidentExperience
          ? step.group?.name === SetupDataTypes.Home && step.dataRelId === unitId
          : selectedProperty?.units.some((unit) => unit.basicInfo.unitId === step.dataRelId)) ||
        step.dataRelId === user?.userId
    );

    acc[curr] = sortStepsByGate(gateStepsFiltered);

    return acc;
  }, {});
}

export function getIsSetupFlowCompleted({ setupFlow, user, property }) {
  // Check if it has incomplete setup flow
  const propertySetupFlowSteps = getSetupFlowStepsFromProperty({ setupFlow, user, property });
  const isSetupFlowCompleted = propertySetupFlowSteps?.every((step) => step.status === StepStatus.Completed);

  return isSetupFlowCompleted;
}

type IsSetupFlowVisibleParams = {
  userId: string;
  agreements: AgreementDetailModel[];
  unitId: string;
};

export function isPlacematVisibleForUnit({ agreements, unitId, userId }: IsSetupFlowVisibleParams) {
  const agreement = agreements
    .filter((agreementItem) => agreementItem.homeAgreements.find((homeAgreement) => homeAgreement.homeId === unitId))
    .find((agreementItem) => agreementItem.agreement.isActive);

  if (!agreement) {
    return false;
  }

  const isUserThePrimaryOwnerOfTheAgreement = agreement.agreementUsers.some(
    (agreementUser) => agreementUser.agreementUser.isPrimaryOwner && agreementUser.agreementUser.userId === userId
  );

  return isUserThePrimaryOwnerOfTheAgreement;
}
