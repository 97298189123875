import React, { useState } from 'react';
import { connect } from 'react-redux';
import AutoSplitPaymentTile from 'components/PaymentTile/AutoSplitPaymentTile/AutoSplitPaymentTile';
import { filter } from 'lodash-es';
import PropTypes from 'prop-types';
import EmptyPaymentTile from '../../components/PaymentTile/EmptyPaymentTile/EmptyPaymentTile';
import PaymentTile from '../../components/PaymentTile/PaymentTile';
import { MODALS } from '../../containercomponents/Modals/modal.consts';
import FullUser from '../../models/composed/FullUser';
import { PaymentMethod, PaymentPreference, PaymentPriority } from '../../models/enums';
import { hideModal, showModal } from '../../store/redux/modals/actions';
import { selectFullUser } from '../../store/redux/user/selectors';

function PaymentTileContainer(props) {
  const {
    showPaymentTileMarginOnMobile,
    fullUser,
    paymentType,
    priority,
    paymentModalProps,
    ignorePaymentMethodId,
    forcePaymentMethod,
    isSelector,
    showPriority,
    noCustomWidth,
    isRenter = false,
    hasLabels,
    onAutoSplitSelection,
    shouldShowAutoSplitPaymentMethod,
    isAutoSplitEnabled,
    onAutoSplitError,
    isReferral,
    variant,
  } = props;

  const [spinner, setSpinner] = useState(false);
  const [highlight, setHighlight] = useState(false);

  const handleMenuOpen = () => {
    setHighlight(true);
  };

  const handleMenuClose = () => {
    setHighlight(false);
  };

  const handlePaymentSelection = async (accountId) => {
    const { onPaymentSelection } = props;

    setSpinner(true);

    try {
      await onPaymentSelection(paymentType, accountId);
    } catch (err) {
      console.error(err);
    }

    setSpinner(false);
  };

  const handleAutoSplitPaymentSelection = async () => {
    setSpinner(true);

    try {
      await onAutoSplitSelection(true);
    } catch (err) {
      console.error(err);
      onAutoSplitError(err);
    }

    setSpinner(false);
  };

  const handleAddPaymentMethod = () => {
    const { showModal: showModalAction } = props;

    showModalAction(MODALS.PAYMENT_MODAL, {
      ...paymentModalProps,
      onPaymentSelection: handlePaymentSelection,
      fullUser,
      onAutoSplitPaymentMethodSelect: onAutoSplitSelection,
      shouldShowAutoSplitPaymentMethod:
        paymentType === PaymentPreference.Maintenance && shouldShowAutoSplitPaymentMethod,
    });
  };

  const paymentMethod = fullUser?.getPaymentMethodFor(
    isReferral ? PaymentPreference.Rent : paymentType,
    priority,
    forcePaymentMethod
  );

  if (priority === PaymentPriority.Maintenance && paymentType !== PaymentPreference.Rent) {
    return null;
  }

  if (!paymentMethod && !isAutoSplitEnabled) {
    return isSelector ? null : (
      <EmptyPaymentTile
        isRenter={isRenter}
        isReferral={isReferral}
        showPaymentTileMarginOnMobile={showPaymentTileMarginOnMobile}
        showSpinner={spinner}
        priority={priority}
        paymentType={paymentType}
        forcePaymentMethod={forcePaymentMethod}
        ignorePaymentMethodId={ignorePaymentMethodId}
        paymentModalProps={paymentModalProps}
        existingPaymentMethodsAvailable={
          !!filter(fullUser?.paymentMethods?.paymentMethods, (pm) => {
            if (forcePaymentMethod) {
              return pm.accountId !== ignorePaymentMethodId && pm.paymentMethod === forcePaymentMethod;
            }
            return pm.accountId !== ignorePaymentMethodId;
          }).length
        }
        onAddPaymentMethod={handleAddPaymentMethod}
        onSelectPaymentMethod={handlePaymentSelection}
        hasLabels={hasLabels}
        shouldShowAutoSplitPaymentMethod={shouldShowAutoSplitPaymentMethod}
        variant={variant}
      />
    );
  }

  if (!paymentMethod && isAutoSplitEnabled) {
    return (
      <AutoSplitPaymentTile
        fullUser={fullUser}
        isRenter={isRenter}
        showPriority={showPriority}
        showPaymentTileMarginOnMobile={showPaymentTileMarginOnMobile}
        highlight={highlight}
        showSpinner={spinner}
        noCustomWidth={noCustomWidth}
        priority={priority}
        isSelector={isSelector}
        paymentType={paymentType}
        paymentModalProps={paymentModalProps}
        hasLabels={hasLabels}
        menuProps={{
          forcePaymentMethod,
          onMenuOpen: handleMenuOpen,
          onMenuClose: handleMenuClose,
          onSelectPaymentMethod: handlePaymentSelection,
          ignorePaymentMethodId,
          shouldShowAutoSplitPaymentMethod: false,
        }}
      />
    );
  }

  return (
    <PaymentTile
      fullUser={fullUser}
      isRenter={isRenter}
      isReferral={isReferral}
      showPriority={showPriority}
      showPaymentTileMarginOnMobile={showPaymentTileMarginOnMobile}
      paymentMethod={paymentMethod}
      highlight={highlight}
      showSpinner={spinner}
      noCustomWidth={noCustomWidth}
      priority={priority}
      isSelector={isSelector}
      paymentType={paymentType}
      paymentModalProps={paymentModalProps}
      hasLabels={hasLabels}
      menuProps={{
        forcePaymentMethod,
        onMenuOpen: handleMenuOpen,
        onMenuClose: handleMenuClose,
        onSelectPaymentMethod: handlePaymentSelection,
        selectedPaymentMethodId: paymentMethod.accountId,
        ignorePaymentMethodId,
        onSelectAutoSplitPaymentMethod: handleAutoSplitPaymentSelection,
        shouldShowAutoSplitPaymentMethod,
        isAutoSplitEnabled,
      }}
    />
  );
}

PaymentTileContainer.propTypes = {
  fullUser: PropTypes.instanceOf(FullUser).isRequired,
  forcePaymentMethod: PropTypes.oneOf(Object.values(PaymentMethod)),
  paymentType: PropTypes.string.isRequired,
  priority: PropTypes.string.isRequired,
  paymentModalProps: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  ignorePaymentMethodId: PropTypes.string,
  showPaymentTileMarginOnMobile: PropTypes.bool,
  isSelector: PropTypes.bool.isRequired,
  onPaymentSelection: PropTypes.func.isRequired,
  showPriority: PropTypes.bool,
  noCustomWidth: PropTypes.bool,
  isRenter: PropTypes.bool,
  hasLabels: PropTypes.bool,
  onAutoSplitSelection: PropTypes.func.isRequired,
  shouldShowAutoSplitPaymentMethod: PropTypes.bool,
  isAutoSplitEnabled: PropTypes.bool,
  onAutoSplitError: PropTypes.func,
  isReferral: PropTypes.bool,
  variant: PropTypes.string,
};

PaymentTileContainer.defaultProps = {
  paymentModalProps: {},
  ignorePaymentMethodId: '',
  forcePaymentMethod: null,
  showPaymentTileMarginOnMobile: false,
  showPriority: true,
  noCustomWidth: false,
  hasLabels: true,
  shouldShowAutoSplitPaymentMethod: false,
  isAutoSplitEnabled: false,
  isReferral: false,
  variant: 'primary',
};

export default connect(
  (state) => ({
    fullUser: selectFullUser(state),
  }),
  {
    showModal,
    hideModal,
  }
)(PaymentTileContainer);
