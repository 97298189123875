import { useContext, useState, type Context } from 'react';
import { useDispatch } from 'react-redux';
import { template } from '@belong/common';
import { Text, Button, Spinner } from '@belong/ui';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { getExclusivityAgreementURL } from 'store/redux/post-inspection-flow/actions';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

type ApprovalContext = {
  group: {
    yearsOfExclusivity: number;
  };
  flow: {
    uniqueId: string;
  };
};

export function ExclusivityBanner() {
  const [isDownloadingExclusivityAgreement, setIsDownloadingExclusivityAgreement] = useState(false);
  const { group, flow } = useContext<ApprovalContext>(ApprovalContext as Context<ApprovalContext>);
  const dispatch = useDispatch();

  async function handleViewAgreementClick() {
    setIsDownloadingExclusivityAgreement(true);

    const { url } = (await dispatch(getExclusivityAgreementURL(flow.uniqueId))) as unknown as Record<'url', string>;

    window.open(url, '_blank');

    setIsDownloadingExclusivityAgreement(false);
  }

  return (
    <div className="rounded border border-solid border-gray bg-light-gray p-sm relative mt-xl md:mt-xs">
      {isDownloadingExclusivityAgreement && <Spinner className="rounded" />}
      <Text as="span">
        {template(POST_INSPECTION_FLOW_STRINGS['exclusivity-banner'], { years: group.yearsOfExclusivity })}
      </Text>
      &nbsp;
      <Button onClick={handleViewAgreementClick} variant="text">
        {POST_INSPECTION_FLOW_STRINGS['exclusivity-agreement-url']}
      </Button>
    </div>
  );
}
