import { useState, useRef, useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import { useOnScreen } from '@belong/common';
import { ApprovalFormSubmitBar } from 'pages/PostInspectionFlow/approval-form-submit-bar/approval-form-submit-bar';

type UseStickyApprovalFormSubmitBarParams = {
  isComplimentaryRepairsExclusivitySelected: boolean;
};

export function useStickyApprovalFormSubmitBar({
  isComplimentaryRepairsExclusivitySelected,
}: UseStickyApprovalFormSubmitBarParams) {
  const [isApprovalFormSubmitBarVisible, setIsApprovalFormSubmitBarVisible] = useState(false);
  const scrollDirection = useRef<'down' | 'up' | null>(null);
  const prevScrollY = useRef(0);
  const ctaRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(ctaRef, '-60px 0px 0px 0px');
  const previousIsOnScreen = useRef(isOnScreen);
  const hasScrolled = useRef(false);
  const transitions = useTransition(isApprovalFormSubmitBarVisible, {
    from: { y: -100 },
    enter: { y: 0 },
    leave: { y: -100 },
  });

  const AnimatedApprovalFormSubmitBar = animated(ApprovalFormSubmitBar);

  useEffect(() => {
    const handleScroll = () => {
      hasScrolled.current = true;

      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY.current) {
        scrollDirection.current = 'down';
      } else if (currentScrollY < prevScrollY.current) {
        scrollDirection.current = 'up';
      }

      prevScrollY.current = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollY]);

  useEffect(() => {
    if (isOnScreen && !previousIsOnScreen.current) {
      setIsApprovalFormSubmitBarVisible(false);
    } else if (!isOnScreen && previousIsOnScreen.current && scrollDirection.current === 'down' && hasScrolled.current) {
      setIsApprovalFormSubmitBarVisible(true);
    }

    previousIsOnScreen.current = isOnScreen;
  }, [isOnScreen, scrollDirection]);

  useEffect(() => {
    hasScrolled.current = false;
  }, [isComplimentaryRepairsExclusivitySelected]);

  return {
    transitions,
    ctaRef,
    isApprovalFormSubmitBarVisible,
    AnimatedApprovalFormSubmitBar,
  };
}
