import {
  PAYMENT_STEPS,
  PAYMENT_STEPS_DO_NOT_EXCEED_GROUP,
  PAYMENT_STEP_KEYS,
} from 'post-inspection/constants/improvements-report';

type UseImprovementReportPaymentStepsParams = {
  isLegacyInvoicing?: boolean;
  inspectionType?: {
    isPreMoveOut: boolean;
    isOnboarding: boolean;
  };
  isDoNotExceedGroup: boolean;
  isComplimentaryRepairsWithExclusivitySelected: boolean;
  canExclusivityBeSelected: boolean;
};

export const useImprovementReportPaymentSteps = ({
  isLegacyInvoicing = false,
  inspectionType,
  isDoNotExceedGroup = false,
  isComplimentaryRepairsWithExclusivitySelected,
  canExclusivityBeSelected,
}: UseImprovementReportPaymentStepsParams): Record<'steps', typeof PAYMENT_STEPS> => {
  if (canExclusivityBeSelected && isComplimentaryRepairsWithExclusivitySelected) {
    return {
      steps: PAYMENT_STEPS.filter((paymentStep) => paymentStep.key === PAYMENT_STEP_KEYS.PaymentMethod),
    };
  }

  if (isDoNotExceedGroup) {
    return { steps: PAYMENT_STEPS_DO_NOT_EXCEED_GROUP };
  }

  if (isLegacyInvoicing || inspectionType.isPreMoveOut) {
    return {
      steps: PAYMENT_STEPS.filter((paymentStep) => paymentStep.key === PAYMENT_STEP_KEYS.Summary),
    };
  }

  return {
    steps: PAYMENT_STEPS,
  };
};
