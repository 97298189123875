import { type ReactNode } from 'react';
import { Form } from '@belong/ui';

type Props = {
  isDisabled: boolean;
  children: ReactNode;
};

export function ApprovalFormSubmitButton({ isDisabled, children }: Props) {
  return (
    <>
      <div className="fixed bottom-0 -ml-2sm w-full z-10 md:hidden">
        <Form.SubmitButton type="submit" size="fluid" fixed disabled={isDisabled}>
          {children}
        </Form.SubmitButton>
      </div>
      <div className="hidden md:flex md:justify-center">
        <Form.SubmitButton type="submit" size="large" disabled={isDisabled}>
          {children}
        </Form.SubmitButton>
      </div>
    </>
  );
}
