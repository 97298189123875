import { useState, useEffect } from 'react';

/**
 * This hook allows you to easily detect when an element is visible on the screen as well as specify how much of the
 * element should be visible before being considered on screen. Perfect for lazy loading images or triggering
 * animations when the user has scrolled down to a particular section.
 *
 * https://usehooks.com/useOnScreen/
 */
function useOnScreen(ref, rootMargin, threshold) {
  if (rootMargin === void 0) {
    rootMargin = '0px';
  }
  if (threshold === void 0) {
    threshold = 0;
  }
  var _a = useState(false),
    isIntersecting = _a[0],
    setIsIntersecting = _a[1];
  useEffect(function () {
    var observer = new IntersectionObserver(function (_a) {
      var entry = _a[0];
      setIsIntersecting(entry.isIntersecting);
    }, {
      rootMargin: rootMargin,
      threshold: threshold
    });
    var current = (ref || {}).current;
    if (current) {
      observer.observe(current);
    }
    return function () {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, []);
  return isIntersecting;
}

export { useOnScreen };
