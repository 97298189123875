import { useState } from 'react';
import { BREAKPOINTS_WIDTHS, useWindowSize } from '@belong/common';
import { Carousel, Image, Text, MediaGalleryModal } from '@belong/ui';
import { MaintenanceMediaModel } from 'api/models';
import clsx from 'clsx';
import styles from './MaintenanceCarousel.module.css';

interface ImageData extends MaintenanceMediaModel {
  url: string;
  mediaType: 'Image' | 'Video' | 'Pdf';
}

type MaintenanceCarouselProps = {
  images: ImageData[];
};

export function MaintenanceCarousel({ images }: MaintenanceCarouselProps) {
  const { width } = useWindowSize();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const orderedImages = images.sort((a, b) => {
    const stageOrder = { After: 1, During: 2, Before: 3 };
    return stageOrder[a.stage] - stageOrder[b.stage];
  });

  const isMobile = width < BREAKPOINTS_WIDTHS.MD;

  return (
    <div className="w-full">
      <Carousel arrowsOnDots={!isMobile} blurredEnds={false} onChange={setCurrentSlide}>
        {orderedImages.map((image, index) => (
          <div key={index} className="relative min-w-full">
            <Image
              src={image.url}
              alt={`Maintenance ${index}`}
              className="object-cover w-full !h-[45vw] !max-h-[400px] cursor-pointer"
              onClick={() => setIsModalOpen(true)}
              onLoad={() => setLoaded(true)}
            />
            {currentSlide === index && image.stage === 'After' && loaded && (
              <div
                className="absolute top-0 left-0 w-full h-full flex items-center justify-center cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                <div className={clsx(styles.stamp)} />
              </div>
            )}
            <div className={clsx('absolute w-full bottom-0 flex justify-end pr-sm', styles.gradient)}>
              {image.stage === 'Before' && (
                <Text variant="p1" fontWeight="semibold" className="text-white">
                  Before
                </Text>
              )}
              {image.stage === 'During' && (
                <Text variant="p1" fontWeight="semibold" className="text-orange">
                  Work In Progress
                </Text>
              )}
              {image.stage === 'After' && (
                <Text variant="p1" fontWeight="semibold" className="text-green">
                  Upon Completion
                </Text>
              )}
            </div>
          </div>
        ))}
      </Carousel>

      <MediaGalleryModal
        isOpen={isModalOpen}
        media={images}
        onClose={() => setIsModalOpen(false)}
        selectedIndex={currentSlide}
      />
    </div>
  );
}
