import { useLocation } from 'react-router-dom';
import { Image, Text } from '@belong/ui';
import queryString from 'query-string';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';

export function ConnectNowBanner() {
  const location = useLocation();

  const { connect } = queryString.parse(location.search);
  const connectParamBoolean = connect === 'true';

  if (!connectParamBoolean) {
    return null;
  }

  return (
    <div className="pt-sm mb-xl">
      <div className="border border-solid border-green bg-green-translucent-light rounded p-sm flex flex-col gap-sm sm:flex-row">
        <Image
          alt="Support"
          src="homeowners/sign-up/support.svg"
          className="h-3xl w-3xl sm:h-2xl sm:w-2xl mx-auto sm:mx-0 sm:my-auto"
        />
        <div className="flex flex-col gap-xs">
          <Text fontWeight="semibold">{HOMEOWNER_ONBOARDING_STRINGS.PropertyBasics.preQualificationTitle}</Text>
          <Text variant="p1">{HOMEOWNER_ONBOARDING_STRINGS.PropertyBasics.preQualificationSubtitle}</Text>
        </div>
      </div>
    </div>
  );
}
