import { Text } from '@belong/ui';
import { STAR_ICON } from 'pages/PostInspectionFlow/constants';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

export function ComplimentaryCoverTag() {
  return (
    <div className="border-3 border-solid rounded border-green bg-green-translucent-medium p-2xs gap-2xs flex items-center justify-center -rotate-12">
      <Text className="text-green" variant="p2">
        {STAR_ICON}
      </Text>
      <Text className="text-green" fontWeight="semibold">
        {POST_INSPECTION_FLOW_STRINGS['improvements.complimentary']}
      </Text>
      <Text className="text-green" variant="p2">
        {STAR_ICON}
      </Text>
    </div>
  );
}
