import { TransferInfoModel } from 'api/models';
import { getFeeFromPaymentMethod } from 'models/utilities/paymentMethods';

const upperCaseFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

type PaymentMethodNamingDisplayProps = {
  paymentMethod: TransferInfoModel['paymentMethod'];
  accountLastDigits: string;
  referenceNumber: string;
  bankName: string;
  otherPaymentType: string;
  accountType: string;
  showFee?: boolean;
  hasFee?: boolean;
};

const PaymentMethodNamingDisplay = ({
  paymentMethod,
  accountLastDigits,
  referenceNumber,
  bankName,
  otherPaymentType,
  accountType,
  showFee = false,
  hasFee = false,
}: PaymentMethodNamingDisplayProps) => {
  if (paymentMethod === 'Ach') {
    return accountLastDigits ? (
      <div>
        <p className="text-ellipsis overflow-hidden whitespace-nowrap">{bankName}</p>
        <p className="text-p1 font-regular">
          {accountType ? upperCaseFirstLetter(accountType) : ''} Ending {accountLastDigits} {showFee && <>&#183;</>}{' '}
          {showFee && <span className="text-green">{getFeeFromPaymentMethod(paymentMethod, hasFee)}</span>}
        </p>
      </div>
    ) : (
      'Add Bank Account'
    );
  } else if (paymentMethod === 'Check') {
    return `Check #${referenceNumber}`;
  } else if (paymentMethod === 'CreditCard') {
    return (
      <div>
        <p className="text-ellipsis overflow-hidden whitespace-nowrap">{bankName}</p>
        <p className="text-p1 font-regular">
          Card Ending {accountLastDigits} {showFee && <>&#183;</>}{' '}
          {showFee && <span className="text-green">{getFeeFromPaymentMethod(paymentMethod, hasFee)}</span>}
        </p>
      </div>
    );
  } else if (paymentMethod === 'WireTransfer') {
    return `Wire Transfer #${referenceNumber}`;
  } else if (paymentMethod === 'PayPal') {
    return `Paypal Transfer #${referenceNumber}`;
  } else if (paymentMethod === 'Other') {
    return `${otherPaymentType || paymentMethod} ${referenceNumber ? ` #${referenceNumber}` : ''}`;
  }
};

export default PaymentMethodNamingDisplay;
