import { useState } from 'react';
import Measure from 'react-measure';
import { Route } from 'react-router-dom';
import WindowSizeListener from 'react-window-size-listener';
import { COOKIES_CONFIG } from '@belong/common';
import { Stepper } from '@belong/ui';
import classNames from 'classnames/bind';
import { clsx } from 'clsx';
import SidebarWithTextBubble from 'components/SidebarWithTextBubble/SidebarWithTextBubble';
import Spinner from 'components/Spinner/Spinner';
import { Grid } from 'forkedlibraries/react-bootstrap';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { parseCookies } from 'nookies';
import PropTypes from 'prop-types';
import { useModal } from '../../../hooks/useModal';
import Page from '../../Page/Page';
import { MobileTipModal } from '../MobileTipModal/MobileTipModal';
import styles from './FlowLayoutV2.module.css';

const cx = classNames.bind(styles);

const sidebarWidth = 380;

const propTypes = {
  sidebarProps: PropTypes.shape({
    step: PropTypes.number.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  employeeAssignment: PropTypes.object.isRequired,
  tip: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.array.isRequired,
  }).isRequired,
  headerMainProps: PropTypes.object,
  steps: PropTypes.array.isRequired,
  stepProps: PropTypes.object,
  children: PropTypes.func,
  navigationComponents: PropTypes.array,
  getRouteProps: PropTypes.func,
  withBalloon: PropTypes.bool,
  progressItems: PropTypes.array,
  showProgressBar: PropTypes.object,
  onlyActiveLabel: PropTypes.bool,
  onProgressStepSelection: PropTypes.func,
  layoutContainerClassName: PropTypes.string,
  formContainerClassName: PropTypes.string,
  sidebarContainerClassName: PropTypes.string,
};

const defaultProps = {
  loading: false,
  navigationComponents: [],
  stepProps: {},
  children: () => null,
  progressItems: [],
  showProgressBar: null,
  onlyActiveLabel: true,
  onProgressStepSelection: null,
  layoutContainerClassName: '',
  formContainerClassName: '',
  sidebarContainerClassName: '',
};

export const FlowLayoutV2 = ({
  employeeAssignment,
  sidebarProps: { step, ...sidebarProps },
  stepProps,
  getRouteProps = () => {},
  tip,
  loading,
  headerMainProps = {},
  steps,
  navigationComponents,
  children,
  progressItems,
  showProgressBar,
  onlyActiveLabel = true,
  onProgressStepSelection,
  layoutContainerClassName = '',
  formContainerClassName = '',
  sidebarContainerClassName = '',
}) => {
  const [measure, setMeasure] = useState({ containerWidth: 0, windowWidth: 0 });
  const [isTipModalOpen, openTipModal, closeTipModal] = useModal();
  const cookies = parseCookies();

  const comeFromTheMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

  const sidebar = (
    <SidebarWithTextBubble
      title={tip.title}
      description={tip.description}
      employeeAssignment={employeeAssignment}
      makeGreen={false}
      showContact
      {...sidebarProps}
    />
  );

  return (
    <Page
      headerMainProps={{
        fixed: true,
        navigationComponents,
        disableLogo: true,
        transparent: false,
        ...headerMainProps,
      }}
      header={!comeFromTheMobileApp}
      footer={false}
    >
      {steps.map((stepConfig) => {
        const Banner = stepConfig.banner;
        if (!Banner) {
          return null;
        }
        return (
          <Route
            key={stepConfig.path}
            path={stepConfig.path}
            exact
            render={(routeProps) => (
              <div className={cx('banner-container')}>
                <Banner openTipModal={openTipModal} {...routeProps} {...stepProps} />
              </div>
            )}
          />
        );
      })}

      <Grid>
        <WindowSizeListener
          onResize={(windowSize) => setMeasure((state) => ({ ...state, windowWidth: windowSize.windowWidth }))}
        />
        <Measure
          bounds
          onResize={({ bounds }) => {
            setMeasure((state) => ({ ...state, containerWidth: bounds.width }));
          }}
        >
          {({ measureRef }) => {
            const { containerWidth, windowWidth } = measure;
            return (
              <div ref={measureRef} className={cx('multi-form-layout', layoutContainerClassName)}>
                <div className={cx('form', formContainerClassName)}>
                  {loading && (
                    <div className={cx('spinner')} style={{ width: containerWidth - sidebarWidth }}>
                      <Spinner />
                    </div>
                  )}
                  {steps
                    ? steps.map((stepConfig, i) => {
                        const StepComponent = stepConfig.component || stepConfig.form;
                        return (
                          <Route
                            key={stepConfig.path}
                            path={stepConfig.path}
                            render={(routeProps) => (
                              <>
                                {showProgressBar && (
                                  <FormLayout>
                                    <div className={clsx('mb-sm', styles.stepper)}>
                                      <Stepper
                                        steps={progressItems}
                                        onlyActiveLabel={onlyActiveLabel}
                                        onStepSelection={onProgressStepSelection}
                                      />
                                    </div>
                                    <StepComponent {...routeProps} {...stepProps} openTipModal={openTipModal} />
                                  </FormLayout>
                                )}
                                {!showProgressBar && (
                                  <StepComponent {...routeProps} {...stepProps} openTipModal={openTipModal} />
                                )}
                              </>
                            )}
                            {...getRouteProps(stepConfig, i)}
                          />
                        );
                      })
                    : children(openTipModal)}
                </div>
                {!!employeeAssignment && (
                  <div
                    className={cx('sidebar', sidebarContainerClassName)}
                    style={{ right: (windowWidth - containerWidth) / 2 - 5 }}
                  >
                    <div className={cx('content-sidebar-with-text')}>{sidebar}</div>
                  </div>
                )}
              </div>
            );
          }}
        </Measure>
      </Grid>
      <MobileTipModal show={isTipModalOpen} onHide={closeTipModal}>
        {!!employeeAssignment && sidebar}
      </MobileTipModal>
    </Page>
  );
};

FlowLayoutV2.propTypes = propTypes;
FlowLayoutV2.defaultProps = defaultProps;
