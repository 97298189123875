import React from 'react';
import classNames from 'classnames/bind';
import { Tag } from 'components/HomeTag/HomeTag';
import { PaymentPreference } from 'models/enums/typings';
import PropTypes from 'prop-types';
import PaymentMenuSelectionContainer from '../../../containers/PaymentSelectionMenuContainer/PaymentMenuSelectionContainer';
import ButtonBase from '../../../corecomponents/ButtonBase/ButtonBase';
import PaymentPreferences from '../../../models/common/PaymentPreferences';
import { PaymentMethod } from '../../../models/enums';
import Spinner from '../../Spinner/Spinner';
import styles from './EmptyPaymentTile.module.css';

const cx = classNames.bind(styles);

function EmptyPaymentTile(props) {
  const {
    onAddPaymentMethod,
    onSelectPaymentMethod,
    existingPaymentMethodsAvailable,
    paymentModalProps,
    ignorePaymentMethodId,
    forcePaymentMethod,
    paymentType,
    hasLabels,
    shouldShowAutoSplitPaymentMethod,
    isReferral,
    isRenter = false,
    variant = 'primary',
  } = props;

  function getTileLabel() {
    if (isReferral) {
      return 'Referral';
    }
    if (paymentType === PaymentPreference.RentalHomeInsurance) {
      return 'Insurance';
    }
    if (paymentType === PaymentPreference.Maintenance) {
      return 'Pro Services';
    }
    if (isRenter && paymentType === PaymentPreference.Rent) {
      return 'Primary for Rent';
    } else if (!isRenter && paymentType === PaymentPreference.Payout) {
      return 'Rent';
    }
  }

  const renderTile = () => {
    const { showSpinner } = props;

    return (
      <div className={cx('empty-tile', variant === 'secondary' && 'empty-tile-secondary')}>
        {showSpinner && <Spinner />}
        <div className={cx('text')}>Add Payment Method</div>
        <div className={cx('add')}>ADD</div>
        {hasLabels && (
          <Tag
            className={cx(
              paymentType === PaymentPreference.Rent || paymentType === PaymentPreference.Payout
                ? 'tag-rent'
                : 'tag-maintenance'
            )}
          >
            {getTileLabel()}
          </Tag>
        )}
      </div>
    );
  };
  if (
    !existingPaymentMethodsAvailable ||
    (shouldShowAutoSplitPaymentMethod && forcePaymentMethod !== PaymentMethod.CreditCard)
  ) {
    return (
      <ButtonBase className={cx('add-button')} onClick={onAddPaymentMethod}>
        {renderTile()}
      </ButtonBase>
    );
  }

  return (
    <PaymentMenuSelectionContainer
      fitToButton
      paymentType={paymentType}
      forcePaymentMethod={forcePaymentMethod}
      menuButtonLabel={renderTile()}
      onSelectPaymentMethod={onSelectPaymentMethod}
      paymentModalProps={paymentModalProps}
      ignorePaymentMethodId={ignorePaymentMethodId}
    />
  );
}

EmptyPaymentTile.propTypes = {
  forcePaymentMethod: PropTypes.oneOf(Object.values(PaymentMethod)),
  onAddPaymentMethod: PropTypes.func.isRequired,
  paymentType: PropTypes.oneOf(Object.values(PaymentPreferences)).isRequired,
  onSelectPaymentMethod: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool,
  existingPaymentMethodsAvailable: PropTypes.bool.isRequired,
  paymentModalProps: PropTypes.object.isRequired,
  ignorePaymentMethodId: PropTypes.string,
  isRenter: PropTypes.bool,
  hasLabels: PropTypes.bool,
  shouldShowAutoSplitPaymentMethod: PropTypes.bool,
  isReferral: PropTypes.bool,
};

EmptyPaymentTile.defaultProps = {
  showSpinner: false,
  ignorePaymentMethodId: '',
  forcePaymentMethod: null,
  hasLabels: true,
  shouldShowAutoSplitPaymentMethod: false,
  isReferral: false,
};

export default EmptyPaymentTile;
