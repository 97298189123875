export const STRINGS = {
  '3-fee-instant': '(3% Fee • Instant)',
  'add-a-credit-card': 'Add A Credit Card',
  'add-a-new-payment-method': 'Add New Payment Method',
  'additional-fees': 'Additional fees may apply for credit cards issued outside the\u00a0US.',
  approve: 'Approve',
  'approve-pay-deposit': 'Approve | Pay Deposit',
  'approve-pay-deposit.pre_move_out': 'Sounds Good',
  'credit-card': 'Credit Card',
  'credit-card-number': 'Credit Card Number',
  cvc: 'CVC',
  done: 'Done',
  'due-now': 'Due Now',
  'due-upfront': 'Due Upfront',
  'e-check-ach': 'eCheck ACH',
  est: 'Est.',
  'exp-mm-yy': 'Exp MM/YY',
  'flagged-items-warning':
    "We'll reach out to discuss any items you flagged. Your plan can still be modified after approving\u00a0here.",
  'free-3-5-business-days': '(FREE • 3-5 Business Days)',
  'from-rent': 'From Rent',
  'get-to-work-belong': 'Get to work, Belong!',
  'improvements-report-success': "Great! We're excited to get to work, and give your home the love it\u00a0deserves.",
  'improvements-report-success.ho-fixes':
    "Great! We'll follow up to check in on your progress. Once done, we'll begin our work and prepare your professional\u00a0listing.",
  'improvements-report-success.pre_move_out':
    'We’ll start marketing your home to potential residents and share the improvement plan. Prices and improvements will be decided after the final\u00a0inspection.',
  'lets-talk': "Let's Talk",
  monthly: '/mo.',
  next: 'Next',
  'not-verified': 'NOT VERIFIED',
  'pay-now': 'Pay',
  'payment-method': 'Payment Method',
  'payment-method-question': 'How would you like to\u00a0pay?',
  'payment-method-step-subtitle':
    'This will be your payment method for Pro Services, which you can change at any time. Upon completion of each, you’ll also have the option to pay in\u00a0installments.',
  'payment-method-step-subtitle-do-not-exceed': 'ACH and credit card options are\u00a0available.',
  'payment-method-step-title': 'How would you like to pay the {dueNow}\u00a0deposit?',
  'payment-method-step-title-exclusivity': 'To secure your complimentary plan, please add payment\u00a0details.',
  'payment-method-step-subtitle-exclusivity': 'There will be a $1 charge to verify your payment\u00a0details.',
  'payment-method-split-step-subtitle':
    'We will automatically set your preference to pay for all Pro Services, starting with your\u00a0Improvements.',
  'payment-method-step-title-split': 'How would you like to pay for\u00a0improvements?',
  'payment-method-step-title-no-due': 'Let’s set up your Pro Services payment\u00a0method.',
  'payment-method-step-title-no-due-confirm': 'Let’s confirm your Pro Services payment\u00a0method.',
  'previous-step': 'previous-step',
  'payment-method-pro-service': 'We will keep this as your payment method for Pro Service\u00a0work.',
  'payment-method-pro-service-empty':
    'We will keep this as your payment method for Pro Service work which you can change at\u00a0anytime.',
  'questions-or-concerns': 'Questions or concerns?',
  'ready-to-get-your-home-loved': 'Ready to get your home\u00a0loved?',
  'ready-to-get-your-home-loved.move-out': 'Ready to get your home\u00a0re-loved?',
  'secured-encrypted-payments': 'This is a Secure 128-bit SSL encrypted\u00a0payment.',
  'successfully-paid': 'Successfully Paid',
  'up-to-12-month-financing-available': 'Up To 12-Month Financing Available',
  'upon-completion': 'Upon Completion',
  zip: 'Zip',
  'deposit-paid': 'Successfully paid {deposit} deposit!',
  'homeowner.moveoyt.total.title': 'Your Estimated Expenses',
};
