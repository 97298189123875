import { useContext, useState } from 'react';
import { ANALYTICS_MODAL_NAMES } from 'analytics';
import ModalV2 from 'components/Modal/ModalV2/ModalV2';
import { ApprovalContext } from 'pages/PostInspectionFlow/steps/Improvements/Approval/ApprovalContext';
import { PaymentStepperBackButton } from 'post-inspection/components/payment-stepper-back-button/payment-stepper-back-button';
import { PaymentMethodProvider } from 'post-inspection/contexts/payment-method';
import { useImprovementReportPaymentSteps } from 'post-inspection/hooks/use-improvements-report-payment-steps';

type Props = {
  onHide: () => void;
  isVisible: boolean;
  inspectionType: { isPreMoveOut: boolean; isOnboarding: boolean };
  isComplimentaryRepairsWithExclusivitySelected: boolean;
  canExclusivityBeSelected: boolean;
  variant: string;
};

export const PaymentModal = ({
  onHide,
  isVisible,
  inspectionType,
  isComplimentaryRepairsWithExclusivitySelected,
  canExclusivityBeSelected,
  variant,
}: Props) => {
  const { isSubmitting, setIsSubmitting, homeownerReportPayment, isLegacyInvoicing, isDoNotExceedGroup } =
    useContext(ApprovalContext);

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const { steps } = useImprovementReportPaymentSteps({
    isLegacyInvoicing,
    inspectionType,
    isDoNotExceedGroup,
    isComplimentaryRepairsWithExclusivitySelected,
    canExclusivityBeSelected,
  });

  const handleBack = () => setCurrentStepIndex((prevCurrentStepIndex) => prevCurrentStepIndex - 1);
  const handleNext = () => setCurrentStepIndex((prevCurrentStepIndex) => prevCurrentStepIndex + 1);

  const showBackButton = !(homeownerReportPayment.groupDueNow === 0 && currentStepIndex === 2);

  const { Component } = steps[currentStepIndex];

  function handleModalHide() {
    setCurrentStepIndex(0);
    onHide();
  }

  return (
    <ModalV2
      closeButton
      name={ANALYTICS_MODAL_NAMES.PAYMENT_PLAN}
      noPadding
      onHide={handleModalHide}
      show={isVisible}
      showSpinner={isSubmitting}
    >
      <div className="relative">
        {Boolean(currentStepIndex) && showBackButton && <PaymentStepperBackButton onClick={handleBack} />}
        <PaymentMethodProvider>
          <Component
            isLegacyInvoicing={isLegacyInvoicing}
            onNextStepClick={handleNext}
            setLoading={setIsSubmitting}
            variant={variant}
          />
        </PaymentMethodProvider>
      </div>
    </ModalV2>
  );
};
