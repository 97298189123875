import { type CSSProperties } from 'react';
import { LinkBase, Image, Text } from '@belong/ui';
import { ApprovalFormSubmitButton } from 'pages/PostInspectionFlow/approval-form-submit-button/approval-form-submit-button';

type Props = {
  children: string;
  isDisabled: boolean;
  style?: CSSProperties;
};

export function ApprovalFormSubmitBar({ children, isDisabled, style }: Props) {
  return (
    <div className="fixed top-0 left-0 w-full bg-navy z-fullscreen" style={{ height: 60, ...style }}>
      <div className="container h-full flex justify-between items-center after:content-none before:content-none">
        <div className="flex items-center">
          <div className="hidden xl:flex mr-xl">
            <LinkBase href="/" aria-label="Go To Homepage" style={{ width: 77, height: 27 }}>
              <Image src="/logos/belong_logo_web_white.svg" alt="" aria-hidden="true" />
            </LinkBase>
          </div>
        </div>
        <div className="flex flex-row h-xl">
          <ApprovalFormSubmitButton isDisabled={isDisabled}>
            <Text variant="p1" fontWeight="semibold">
              {children}
            </Text>
          </ApprovalFormSubmitButton>
        </div>
      </div>
    </div>
  );
}
