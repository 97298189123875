import { Text, Money } from '@belong/ui';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

type Props = {
  cost: number;
};

export function ImprovementsReportSavings({ cost }: Props) {
  return (
    <div className="flex flex-col gap-xs">
      <div className="flex justify-between items-center">
        <Text>{POST_INSPECTION_FLOW_STRINGS['improvements-report-cost.repairs-valued-at']}</Text>
        <Money value={cost} format="DOLLARS_WITH_CENTS" fontWeight="semibold" />
      </div>
      <div className="flex justify-between items-center">
        <Text>{POST_INSPECTION_FLOW_STRINGS['improvements-report-cost.you-pay']}</Text>
        <Money className="text-green" value={0} format="DOLLARS_NO_CENTS" fontWeight="semibold" />
      </div>
    </div>
  );
}
