import { isChecked } from '@belong/common';
import { Form, ButtonSelectorField, CheckboxField } from '@belong/ui';
import { clsx } from 'clsx';
import { ApprovalFormSubmitButton } from 'pages/PostInspectionFlow/approval-form-submit-button/approval-form-submit-button';
import { COMPLIMENTARY_REPAIRS_FORM_OPTIONS } from 'pages/PostInspectionFlow/constants';
import { ExclusivityBanner } from 'pages/PostInspectionFlow/exclusivity-banner/exclusivity-banner';
import { useStickyApprovalFormSubmitBar } from 'pages/PostInspectionFlow/hooks';
import type { ComplimentaryRepairsFormValues } from 'pages/PostInspectionFlow/types';
import { getComplimentaryRepairsSubmitButtonText } from 'pages/PostInspectionFlow/utils';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';
import css from './approval-form.module.css';

type Props = {
  onComplimentaryRepairsOptionChange: (
    complimentaryRepairsOption: ComplimentaryRepairsFormValues['complimentaryRepairsOption']
  ) => Promise<void>;
  onSubmit: (values: ComplimentaryRepairsFormValues) => void;
  isPreMoveOutInspection: boolean;
  canExclusivityBeSelected: boolean;
  isComplimentaryRepairsExclusivitySelected: boolean;
};

export function ApprovalForm({
  onComplimentaryRepairsOptionChange,
  onSubmit,
  isPreMoveOutInspection,
  canExclusivityBeSelected,
  isComplimentaryRepairsExclusivitySelected,
}: Props) {
  const { transitions, ctaRef, AnimatedApprovalFormSubmitBar } = useStickyApprovalFormSubmitBar({
    isComplimentaryRepairsExclusivitySelected,
  });

  return (
    <Form<ComplimentaryRepairsFormValues>
      initialValues={{
        complimentaryRepairsOption: 'complimentary-repairs-with-exclusivity',
        agreeToTermsAndConditions: true,
      }}
      onSubmit={onSubmit}
    >
      {({ values, errors, setFieldValue }) => {
        const { agreeToTermsAndConditions, complimentaryRepairsOption } = values;

        const isComplimentaryRepairsWithExclusivitySelected =
          complimentaryRepairsOption === 'complimentary-repairs-with-exclusivity';

        const isSubmitButtonDisabled =
          isComplimentaryRepairsWithExclusivitySelected && typeof errors?.agreeToTermsAndConditions !== 'undefined';

        const submitButtonText = getComplimentaryRepairsSubmitButtonText({
          complimentaryRepairsOption,
          isPreMoveOutInspection,
          canExclusivityBeSelected,
        });

        return (
          <>
            {canExclusivityBeSelected && (
              <>
                <ButtonSelectorField
                  options={COMPLIMENTARY_REPAIRS_FORM_OPTIONS}
                  name="complimentaryRepairsOption"
                  itemWidth="fluid"
                  itemHeight="small"
                  itemContainerClassName="md:last:mt-0 last:mt-sm"
                  onChange={(
                    newComplimentaryRepairsOption: ComplimentaryRepairsFormValues['complimentaryRepairsOption']
                  ) => {
                    onComplimentaryRepairsOptionChange(newComplimentaryRepairsOption);

                    if (newComplimentaryRepairsOption === 'complimentary-repairs-with-exclusivity') {
                      setFieldValue('agreeToTermsAndConditions', true);
                    }
                  }}
                />
                <div className={clsx(isComplimentaryRepairsWithExclusivitySelected ? 'mb-xl' : 'mb-2xl')}>
                  {isComplimentaryRepairsWithExclusivitySelected && (
                    <>
                      <div className="mt-sm mb-xl">
                        <ExclusivityBanner />
                      </div>
                      <div className="flex items-center justify-center">
                        <CheckboxField
                          fontWeight={agreeToTermsAndConditions ? 'semibold' : 'regular'}
                          labelClassName={css.exclusivityCheckbox}
                          labelTextClassName="text-navy"
                          unselectedClassName="text-navy"
                          size="small"
                          name="agreeToTermsAndConditions"
                          label={POST_INSPECTION_FLOW_STRINGS['exclusivity-agreement']}
                          inputContainerClass="self-start"
                          validate={isChecked}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            <div className="contents md:block" ref={ctaRef}>
              <ApprovalFormSubmitButton isDisabled={isSubmitButtonDisabled}>
                {submitButtonText}
              </ApprovalFormSubmitButton>
            </div>
            <div className="hidden md:contents">
              {transitions(
                (styles, isApprovalFormSubmitBarVisible) =>
                  isApprovalFormSubmitBarVisible && (
                    <AnimatedApprovalFormSubmitBar isDisabled={isSubmitButtonDisabled} style={styles}>
                      {submitButtonText}
                    </AnimatedApprovalFormSubmitBar>
                  )
              )}
            </div>
          </>
        );
      }}
    </Form>
  );
}
