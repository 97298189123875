import { Text } from '@belong/ui';
import { clsx } from 'clsx';
import { POST_INSPECTION_FLOW_STRINGS } from 'strings/post-inspection-flow';

export const IMPROVEMENTS_BENEFITS = [
  {
    imageSrc: '/improvement-reports/quality-01.svg',
    title: POST_INSPECTION_FLOW_STRINGS['quality.markup'],
    description: POST_INSPECTION_FLOW_STRINGS['quality.markupDescription'],
  },
  {
    imageSrc: '/improvement-reports/quality-02.svg',
    title: POST_INSPECTION_FLOW_STRINGS['quality.penny'],
    description: POST_INSPECTION_FLOW_STRINGS['quality.pennyDescription'],
  },
  {
    imageSrc: '/improvement-reports/quality-03.svg',
    title: POST_INSPECTION_FLOW_STRINGS['quality.guarantee'],
    description: POST_INSPECTION_FLOW_STRINGS['quality.guaranteeDescription'],
  },
  {
    imageSrc: '/improvement-reports/quality-04.svg',
    title: POST_INSPECTION_FLOW_STRINGS['quality.liability'],
    description: POST_INSPECTION_FLOW_STRINGS['quality.liabilityDescription'],
  },
];

/**
 * This should probably be at a higher level but do not want to pollute the already crowded constants file in this stack.
 */
export const STAR_ICON = '\u2605';

export const COMPLIMENTARY_REPAIRS_FORM_OPTIONS = [
  {
    value: 'pay-out-of-pocket',
    label: POST_INSPECTION_FLOW_STRINGS['pay-out-of-pocket'],
  },
  {
    value: 'complimentary-repairs-with-exclusivity',
    renderContent: (isSelected: boolean) => (
      <div className="flex flex-col items-center justify-center">
        <Text className={clsx(isSelected && 'text-white')} fontWeight={isSelected ? 'semibold' : 'regular'}>
          {POST_INSPECTION_FLOW_STRINGS['complimentary-repairs']}
        </Text>
        <Text className={clsx(isSelected && 'text-white')} fontWeight={isSelected ? 'semibold' : 'regular'}>
          {POST_INSPECTION_FLOW_STRINGS['with-exclusivity']}
        </Text>
      </div>
    ),
    tag: 'Recommended',
    tagClassName: 'top-[5px] font-semibold',
  },
];

export const EVENTS = {
  EXCLUSIVITY_ENABLED: 'exclusivity-enabled',
};
