import * as FullStory from '@fullstory/browser';

type FullStoryEvent = {
  eventName:
    | 'flow-started'
    | 'flow-step-viewed'
    | 'flow-step-completed'
    | 'flow-completed'
    | 'flow-exited'
    | 'flow-error'
    | 'flow-already-completed';
  metadata: {
    homeId?: string;
    source?: 'Email' | 'App' | 'Placemat';
    flowType: 'Improvements' | 'Pricing';
    flowSubType: 'Onboarding' | 'moveOut' | 'preMoveOut';
    // Added both stepEnum and stepDisplayName since the enum is not always the same as the display name.
    stepEnum: string; // e.g. 'get-started'
    stepDisplayName: string; // e.g. 'Intro'
  };
};

export const trackFullStoryEvent = ({ eventName, metadata }: FullStoryEvent) => {
  if (typeof window === 'undefined') return;

  FullStory.event(eventName, metadata);
};
